<template>
  <div class="view-notes container-fluid">
    <div class="card mb-5">
      <h6 class="mb-3">
        Minhas notas
      </h6>

      <div v-if="loading" class="text-center my-5">
        <b-spinner />
      </div>

      <h4 v-else-if="!myCourses.length" class="text-center font-weight-normal">
        Não foram encontrados cursos
      </h4>

      <template v-else>
        <note-collapse
          v-for="course in myCourses"
          class="my-1"
          :item="course"
          :key="course.id"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import NoteCollapse from "../components/notes/NoteCollapse.vue";
export default {
  components: { NoteCollapse },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(["myCourses"])
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("getCourses").finally(() => (this.loading = false));
  }
};
</script>
