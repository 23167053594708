<template>
  <div class="component-note-collapse" :class="{ course: isCourse }">
    <div v-if="type === 'note'" class="note">
      <div class="d-flex justify-content-between mb-1">
        <span class="note-time">
          {{ humanizedSeconds(item.current_lesson_duration) }}
        </span>
        <!-- <button class="btn btn-sm p-0 text-secondary font-weight-normal">
          <i class="fa fa-ellipsis-vertical px-2" style="font-size: 12px;" />
        </button> -->
      </div>
      <span>
        {{ item.text }}
      </span>
    </div>
    <template v-else>
      <button v-b-toggle="String(item.id)" class="btn toggle-collapse">
        <div class="d-flex align-items-center">
          <img :src="courseImg" alt="course-img" v-if="isCourse" />
          <span>
            {{ name }}
          </span>
        </div>
        <i class="fa fa-angle-down" />
      </button>
      <b-collapse :id="String(item.id)" @show="getChildren">
        <div class="collapse-content" :class="{ course: isCourse }">
          <div class="text-center mt-2" v-if="children === null">
            <b-spinner />
          </div>
          <div class="text-center mt-3 mb-1" v-else-if="!children.length">
            {{ noChildrenMessage }}
          </div>

          <template v-else>
            <div
              v-for="(child, i) in children"
              class="collapse-item"
              :key="child.id"
            >
              <svg height="0" width="33">
                <line
                  x1="1"
                  x2="1"
                  y1="0"
                  :y2="i + 1 === children.length ? 24.5 : '100%'"
                  stroke="#A6A7B9"
                />
                <path
                  fill="none"
                  stroke="#A6A7B9"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M32 32.1579H9C4.58172 32.1579 1 28.5762 1 24.1579 M28 29L32.7 32.2 28 35"
                />
              </svg>
              <!-- <div class="arrow" /> -->
              <note-collapse :item="child" :type="childType" class="mt-2" />
            </div>
          </template>
        </div>
      </b-collapse>
    </template>
  </div>
</template>
<script>
import { humanizedSeconds } from "@/functions/helpers";
export default {
  name: "note-collapse",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: "course"
    }
  },
  data() {
    return {
      children: null,
      loading: false
    };
  },
  computed: {
    name() {
      if (this.item?.member_config?.name) return this.item?.member_config?.name;
      return this.item.name;
    },
    isCourse() {
      return this.type === "course";
    },
    courseImg() {
      return (
        this.item?.member_config?.cover_blitz_club ||
        this.item?.member_config?.brand ||
        require("../../assets/images/product-placeholder.svg")
      );
    },
    childType() {
      return {
        course: "module",
        module: "lesson",
        submodule: "lesson",
        lesson: "note"
      }[this.type];
    },
    noChildrenMessage() {
      return {
        course: "Não foram encontrados módulos nesse curso",
        module: "Não foram encontradas aulas nesse módulo",
        submodule: "Não foram encontradas aulas nesse submódulo",
        lesson: "Não foram encontradas anotações nessa aula"
      }[this.type];
    },
    storeAction() {
      return {
        course: this.getModules,
        module: this.getLessons,
        submodule: this.getLessons,
        lesson: this.getNotes
      }[this.type];
    }
  },
  methods: {
    humanizedSeconds,
    getChildren() {
      if (this.children === null) {
        return this.storeAction();
      }
    },
    getModules() {
      this.$store
        .dispatch("notes/getModules", this.item?.product_id)
        .then(results => (this.children = results))
        .catch(() => (this.children = []));
    },
    getLessons() {
      this.$store
        .dispatch("notes/getLessons", this.item?.id)
        .then(results => (this.children = results))
        .catch(() => (this.children = []));
    },
    getNotes() {
      this.$store
        .dispatch("notes/getNotes", this.item?.id)
        .then(results => (this.children = results))
        .catch(() => (this.children = []));
    }
  }
};
</script>
<style lang="scss" scoped>
.component-note-collapse {
  border-radius: 5px;
  transition: 0.2s all;
  border: 1px solid transparent;
  .note {
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    .note-time {
      font-size: 12px;
    }
  }
  .toggle-collapse {
    margin: -1px -1px 0;
    padding: 12px;
    font-weight: 400;
    border-radius: 4px;

    width: calc(100% + 2px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 70px;
      height: 70px;
      margin-right: 12px;
      border-radius: 4.375px;

      object-fit: cover;
      object-position: center;
    }
  }
  .collapse-content {
    padding: 4px 0 16px 12px;
    line-break: anywhere;
    &.course {
      padding-right: 12px;
    }
    .collapse-item {
      display: grid;
      column-gap: 10px;
      grid-template-columns: 53px 1fr;

      svg {
        margin-left: 20px;
        height: 100%;
      }

      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
        svg {
          display: none;
        }
      }
    }
  }
}
</style>
